import { alertCategories } from "@/dtos/AlertaDTO";
import { formatISO, parse } from "date-fns";

export const sentenceCase = (value: string): string => {
    const lowerCase = value.slice(1, value.length)?.toLowerCase();
    const upperCase = value[0]?.toUpperCase();
    if(lowerCase && upperCase) {
        return upperCase + lowerCase;
    }
    return value;
}; 

export const titleCase = (value: string): string  => {
    return value.split(" ").map(word => {
        return sentenceCase(word);
    }).join(" ");
};

export const isCpf = (value: string): boolean => {
    const cpf = value.replace(/\D/g, '');

    return cpf.length === 11;
};

export const maskCpf = (value: string): string => {
    return `${value.substr(0,3)}.${value.substr(3,3)}.${value.substr(6,3)}-${value.substr(9,3)}`;
};

export const removeCpfMask = (value: string): string => {
    return value.replace(new RegExp('[.-]', 'gi'), '');
};

export const toISODate = (value: string) => {
    const date = parse(value, 'dd/MM/yyyy', new Date());
    return formatISO(date, { representation: 'date' });
};

export const tradutorAlerta = ( category: alertCategories, description?: string ) => {
    switch (category) {
        case "Manual":
            return "Manual";
        case "NoFace":
            return "Nenhuma face encontrada";
        case "MultipleFace":
            return "Múltiplas faces encontradas";
        case "OtherFace":
            return "Face de outra pessoa encontrada";
        case "HeadUp":
            return "Cabeça para cima";
        case "HeadDown":
            return "Cabeça para baixo";
        case "HeadRight":
            return "Cabeça para direita";
        case "HeadLeft":
            return "Cabeça para esquerda";
        case "HeadRolled":
            return "Cabeça inclinada";
        case "NoGaze":
            return "Nenhum olhar detectado";
        case "GazeUp":
            return "Olhar direcionado para cima";
        case "GazeDown":
            return "Olhar direcionado para baixo";
        case "GazeLeft":
            return "Olhar direcionado para esquerda";
        case "GazeRight":
            return "Olhar direcionado para direita";
        case "Audio":
            return "Audio encontrado";
        case "FocusOff":
            return "Perdeu o foco";
        case "CellPhone":
            return "Celular detectado";
        case "ExtractFailed":
            return "Não foi possivel extrair informação biométrica";
        case "ShortExam":
            return "Duracao do exame abaixo de 15min";
        case "VeryShortExam":
            return "Duração do exame abaixo de 10min";  
        case "EnvironmentMultiplePeople":
            return "Múltiplas pessoas detectadas no ambiente";  
        case "EnvironmentNoPeople":
            return "Nenhuma pessoa no ambiente";  
        default:
            return description;
    }
};